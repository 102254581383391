{
  "emmyTalk": {
    "messagesTab": {
      "title": "Messages"
    },
    "candidatesTab": {
      "title": "Candidates"
    },
    "projectsTab": {
      "title": "Projects"
    },
    "search": {
      "active": "Active",
      "inActive": "Inactive"
    },
    "send": {
      "placeholder": "Reply here....",
      "sendButton": "Send"
    },
    "project": "Project"
  },
  "home": {
    "greeting": "Hello ",
    "greetingMorning": "Good morning ",
    "greetingDay": "Good day ",
    "greetingEvening": "Good evening ",
    "lastCandidates": "Last 5 candidates",
    "candidateInterviews": "Candidate interviews",
    "noInterviewPlanned": "No interview planned",
    "project": "Project",
    "interview": "Interview",
    "marketOverview": "Market Overview (Preview)",
    "pastInterview": "Past Interviews",
    "futureInterview": "Future Interviews"
  },
  "report": {
    "bannerMessages": {
      "error": "An error occurred while loading the report.",
      "lagecy": "Using lagecy authentication mode.",
      "loading": "Loading",
      "maintenance": "Maintance in progress. Please try again later.",
      "unauthorized": "You don't have permissions to view this page."
    }
  },
  "project": {
    "details": "Details",
    "consultant": "Consultant",
    "quickView": {
      "title": "Project - QuickView",
      "contacted": "Contacted",
      "feedbackPending": "Feedback pending",
      "inCommunication": "In communication",
      "interview": "Selection process",
      "inDfindProcess": "In process",
      "customerProcess": "In customer process",
      "rejection": "Rejections | Rejection | Rejections",
      "total": "Total Candidates"
    },
    "information": {
      "title": "Project - Information",
      "current": "Active",
      "profilePresentation": "Presentation",
      "firstInterviewPhase": "First interview phase",
      "secondInterviewPhase": "Second interview phase",
      "contractPhase": "Contracting phase",
      "total": "Total",
      "rejectionAfterPresentation": "No rejection after profile presentation | Rejection after profile presentation | Rejections after profile presentation",
      "invitationFirstInterview": "No invitation to first customer interview | Invitation to first customer interview | Invitations to first customer interview",
      "rejectionAfterFirstInterview": "No rejection after first interview | Rejection after first interview | Rejections after first interview",
      "invitationFollowOnInterview": "No invitation to follow-on interviews | Invitation to follow-on interviews | Invitations to follow-on interviews",
      "rejectionAfterFollowOnInterview": "No rejection after follow-on interviews | Rejection after follow-on interviews | Rejections after follow-on interviews",
      "numberOffers": "Number of offers",
      "declinedOffers": "No declined offer | Declined offer | Declined offers",
      "profilePresentationCurrent": "Profile presentation - Current",
      "firstInterviewPhaseCurrent": "First interview phase - Current",
      "secondInterviewPhaseCurrent": "Second interview phase - Current",
      "contractNegotiationsCurrent": "Contracting phase - Current",
      "interviewPhase": "Interview Phase",
      "rejections": "Rejections",
      "hidden": "Not shared"
    },
    "reportTab": {
      "segmentTitle": "Report"
    },
    "candidatesTab": {
      "segmentTitle": "Candidates"
    },
    "jobDetailsTab": {
      "segmentTitle": "Job Details",
      "overview": {
        "title": "Overview",
        "status": "Status",
        "jobStartDate": "Job start date",
        "salary": "Salary",
        "location": "Location"
      },
      "searchProfile": {
        "title": "Search profile",
        "jobDescription": "Job description",
        "noJobDescription": "No job description available",
        "koQuestions": "K.O. Questions",
        "noKoQuestions": "No K.O. questions available",
        "departmentProfile": "Overview",
        "noDepartmentProfile": "No department profile available"
      },
      "projectTeam": {
        "title": "Project team",
        "contactHr": "Contact HR",
        "consultant": "Consultant",
        "contactField": "Contact department",
        "furtherConsultants": "Further Consultants"
      }
    },
    "documentsTab": {
      "segmentTitle": "Documents",
      "title": "Notes & attachments",
      "noDocuments": "No documents found",
      "fileTitle": "Document title",
      "fileFormat": "Document format",
      "fileType": "Document type",
      "fileDownload": "Download",
      "cv": "Curriculum Vitae",
      "projectList": "Project list",
      "report": "Report",
      "certificate": "Certificate",
      "misc": "Misc.",
      "binaryFormat": "Binary",
      "imageFormat": "Image",
      "github": "GitHub",
      "other": "Other",
      "xing": "Xing",
      "linkedin": "LinkedIn",
      "grades": "Grades",
      "referenceLetter": "Reference Letter",
      "coverLetter": "Cover Letter",
      "applicationPackage": "Application Package"
    },
    "historyTab": {
      "segmentTitle": "Project History"
    },
    "notFound": "Project not found or unauthorized to access"
  },
  "candidates": {
    "details": "Details",
    "toggleActiveCandidates": "Shows active candidates",
    "toggleInActiveCandidates": "Shows inactive candidates"
  },
  "candidate": {
    "project": "Project",
    "previousCandidate": "Previous",
    "nextCandidate": "Next",
    "rejectionReason": "Rejection reason",
    "nextInterview": "Next interview is:",
    "nextFollowUpInterview": "Follow up interview:",
    "documentsTab": {
      "segmentTitle": "Documents",
      "cv": "CV",
      "projectList": "Project list",
      "report": "Report",
      "certificate": "Certificate",
      "misc": "Other",
      "imageFormat": "Photo",
      "other": "Other",
      "photo": "Photo",
      "logo": "Logo",
      "linkedin": "LinkedIn",
      "xing": "Xing",
      "github": "GitHub",
      "noDocument": "No documents found",
      "grades": "Grades",
      "referenceLetter": "Reference Letter",
      "coverLetter": "Cover Letter",
      "applicationPackage": "Application Package"
    },
    "informationTab": {
      "segmentTitle": "Information",
      "dateOfBirth": "Date of birth",
      "university": "University",
      "highestDegree": "Highest degree",
      "travelWillingness": "Willingness to travel",
      "relocationWillingness": "Willingness to relocate",
      "languageSkills": "Language skills (fluency)",
      "salaryExpectation": "Salary Expectation",
      "remoteWork": "Remote Days"
    },
    "evaluationTab": {
      "segmentTitle": "Assessment",
      "projectExperience": "Project experience",
      "personalGoal": "Personal goals",
      "changeMotivation": "Change motivation",
      "answerToKOQuestions": "Answer to K.O. questions",
      "noData": "No data available"
    },
    "scoringTab": {
      "segmentTitle": "Scoring",
      "overview": "Overview",
      "requirements": "Requirements",
      "personality": "Personality",
      "competence": "Competence",
      "jobAssessment": "Job Assessment",
      "motivationalDrivers": "Motivational Drivers"
    },
    "notFound": "Candidate not found or unauthorized to access",
    "rejection": {
      "Academic background does not fit": "Academic background does not fit",
      "Already talked to customer": "Already talked to customer",
      "Company not attractive": "Company not attractive",
      "Currently no motivation for change": "Currently no motivation for change",
      "Different salary expectation": "Different salary expectation",
      "Insufficient language skills": "Insufficient language skills",
      "Lack of mobility": "Lack of mobility",
      "No reason given": "No reason given",
      "Only available part-time": "Only available part-time",
      "Other contract offer accepted": "Other contract offer accepted",
      "Personality does not fit the profile": "Personality does not fit the profile",
      "Position filled": "Position filled",
      "Position unattractive": "Position unattractive",
      "Remains with current employer": "Remains with current employer",
      "Wrong job level": "Wrong job level",
      "Wrong skillset": "Wrong skillset",
      "Only interested in freelance positions": "Only interested in freelance positions"
    }
  },
  "login": {
    "usernamePlaceholder": "Username",
    "passwordPlaceholer": "Password",
    "loginButton": "Log in",
    "logoutButton": "Log out",
    "backButton": "Back",
    "cancle": "Cancle",
    "confirmNewPassword": "Confirm",
    "explainOrgId": "What is a Organization Identifier?",
    "forgotPassword": "Forgot your password?",
    "organizationPlaceholder": "Organization ID",
    "passwordReset": "New Password",
    "passwordResetConfirm": "Confirm New Password",
    "requestPasswordReset": "Send E-Mail",
    "resetPassword": "Reset Password",
    "welcomeMessage": "Welcome, please log in.",
    "explainReset": "Check your Inbox and enter your received code as well as your new desired password and conform it.",
    "explainOrgIdDescription": "The organization ID is used to set up your environment. You receive this key from your administrator. In case you have any trouble feel free to contact support at",
    "resetSuccess": "Passwort-Reset Successfull",
    "forgotSuccess": "Reset-PIN sent"
  },
  "search": {
    "searchPlaceholder": "Search...",
    "cancel": "Cancel",
    "projects": "Projects",
    "candidates": "Candidates",
    "projectsNotFound": "No projects found",
    "candidatesNotFound": "No candidates found"
  },
  "breadcrumbs": {
    "projects": "Projects",
    "candidates": "Candidates"
  },
  "progressBar": {
    "project": {
      "contact": "Briefing",
      "prequalification": "Selection process",
      "evaluation": "Selection process",
      "presentation": "Profile presentation",
      "firstInterview": "Interview phase",
      "contract": "Contract negotiation",
      "placement": "Placement",
      "followupInterview": "Interview phase"
    },
    "candidate": {
      "presentation": "Profilübermittlung",
      "firstInterview": "Erstes Interview",
      "followUpInterviews": "Folgeinterview(s)",
      "contractNegotiations": "Vertragsverhandlungen",
      "placement": "Platzierung"
    }
  },
  "notifications": {
    "title": "Notifications",
    "read": "Already read",
    "unRead": "New notifications",
    "NoUnRead": "No new notifications",
    "markAllAsRead": "Mark all as read",
    "newMessage": "New message",
    "newProject": "The project was added",
    "newCandidate": "A candidate was added",
    "justNow": "Just now",
    "minutesAgo": "One minute ago | {timeAgo} minutes ago",
    "hoursAgo": "One hour ago | {timeAgo} hours ago",
    "daysAgo": "One day ago | {timeAgo} days ago",
    "monthAgo": "One month ago | {timeAgo} month ago",
    "yearsAgo": "One year ago | {timeAgo} years ago"
  },
  "sidebar": {
    "home": "Home",
    "projects": "Projects",
    "projectsOverview": "Projects overview",
    "candidates": "Candidates",
    "emmybi": {
      "title": "EmmyBI",
      "current": "Current",
      "history": "History",
      "targetCompanies": "Target companies"
    },
    "emmyTalk": "EmmyTalk",
    "allProjects": "Show all projects..."
  },
  "header": {
    "account": {
      "title": "Account"
    }
  },
  "languages": {
    "African": "African",
    "Albanian": "Albanian",
    "Amharic": "Amharic",
    "Arabic": "Arabic",
    "Armenian": "Armenian",
    "Azerbaijani": "Azerbaijani",
    "Belarusian": "Belarusian",
    "Bengali": "Bengali",
    "Bhutanese": "Bhutanese",
    "Bislama": "Bislama",
    "Bosnian": "Bosnian",
    "Bulgarian": "Bulgarian",
    "Burmese": "Burmese",
    "Cambodian": "Cambodian",
    "Catalan": "Catalan",
    "Chinese": "Chinese",
    "Croatian": "Croatian",
    "Czech": "Czech",
    "Danish": "Danish",
    "Dari": "Dari",
    "Dutch": "Dutch",
    "English": "English",
    "Estonian": "Estonian",
    "Filipino": "Filipino",
    "Finnish": "Finnish",
    "French": "French",
    "Georgian": "Georgian",
    "German": "German",
    "Greek": "Greek",
    "Hebrew": "Hebrew",
    "Hindi": "Hindi",
    "Hungarian": "Hungarian",
    "Icelandic": "Icelandic",
    "Indonesian": "Indonesian",
    "Irish": "Irish",
    "Italian": "Italian",
    "Japanese": "Japanese",
    "Kazakh": "Kazakh",
    "Korean": "Korean",
    "Kyrgyz": "Kyrgyz",
    "Lao": "Lao",
    "Latin": "Latin",
    "Latvian": "Latvian",
    "Lithuanian": "Lithuanian",
    "Macedonian": "Macedonian",
    "Madagassisch": "Madagassisch",
    "Malagasy": "Malagasy",
    "Maldivian": "Maldivian",
    "Maltese": "Maltese",
    "Mongolian": "Mongolian",
    "Nauruan": "Nauruan",
    "Nepalese": "Nepalese",
    "Norwegian": "Norwegian",
    "Persian": "Persian",
    "Polish": "Polish",
    "Portuguese": "Portuguese",
    "Romanian": "Romanian",
    "Russian": "Russian",
    "Samoan": "Samoan",
    "Serbian": "Serbian",
    "Sinhala": "Sinhala",
    "Slovak": "Slovak",
    "Slovenian": "Slovenian",
    "Somali": "Somali",
    "Spanish": "Spanish",
    "Swahili": "Swahili",
    "Swedish": "Swedish",
    "Tadschikisch": "Tadschikisch",
    "Tajik": "Tajik",
    "Thai": "Thai",
    "Turkish": "Turkish",
    "Turkmen": "Turkmen",
    "Ukrainian": "Ukrainian",
    "Urdu": "Urdu",
    "Uzbek": "Uzbek",
    "Vietnamese": "Vietnamese"
  },
  "components": {
    "candidateSlider": {
      "noCandidatesLabel": "No visibile candidates"
    }
  }
}
